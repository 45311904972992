import {
  sendGetRequest,
  sendPutRequest,
  sendGetRequestWithFullResponse,
  sendPostRequest,
  sendDeleteRequest,
} from '@/services/api.service'
import { API_LEADS } from '@/config/api.config'

export const getLeadsFormsApi = (brandID, locale) => {
  return sendGetRequest(`${API_LEADS.BASE_API_URL}${brandID}/forms`, { headers: { locale: locale } })
}

export const getFormLeadsApi = (brandID, formId, urlParams, filterParams) => {
  return sendGetRequest(`${API_LEADS.BASE_API_URL}${brandID}/forms/${formId}/leads?${urlParams}${filterParams}`)
}

export const getLeadExportApi = (brandID, formId, urlParams, filterParams) => {
  return sendGetRequestWithFullResponse(
    `${API_LEADS.BASE_API_URL}${brandID}/forms/${formId}/leads/export?${urlParams}${filterParams}`,
    {
      headers: { 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
      responseType: 'arraybuffer',
    }
  )
}

export const getLPELocationsApi = (clientId, search) => {
  return sendGetRequest(`${API_LEADS.BASE_API_URL}/${API_LEADS.CLIENT}/${clientId}/locations?search=${search}`)
}

export const getConversionDashboardApi = ({ formId, clientId, filters }) => {
  const params = {}
  for (const filter of filters) {
    params[filter.slug] = filter.value
  }
  return sendGetRequest(
    `${API_LEADS.BASE_API_URL}/${API_LEADS.CLIENT}/${clientId}/${API_LEADS.FORMS}/${formId}/${API_LEADS.CONVERSION}`,
    filters && { params }
  )
}

export const putLeadStatusApi = url => {
  return sendPutRequest(`${API_LEADS.BASE_API_URL}${url}`)
}

export const deleteLeadApi = url => {
  return sendDeleteRequest(`${API_LEADS.BASE_API_URL}${url}`)
}

export const putLeadOwnerApi = (url, notify, email) => {
  return sendPutRequest(`${API_LEADS.BASE_API_URL}${url}`, {
    notify,
    email,
  })
}

export const putLeadLocationApi = (url, locationId) => {
  return sendPutRequest(`${API_LEADS.BASE_API_URL}${url}`, {
    locationId,
  })
}

export const putLeadEditApi = async (brandID, formId, leadId, leadContent) => {
  try {
    await sendPutRequest(`${API_LEADS.BASE_API_URL}${brandID}/forms/${formId}/formSubmissions/${leadId}`, leadContent)
  } catch (error) {
    if (error.status !== 304) {
      throw error
    }
  }
}

export const getLeadsFiltersApi = (brandID, formId) => {
  return sendGetRequest(`${API_LEADS.BASE_API_URL}${brandID}/forms/${formId}/leads/fetchFilters`)
}

export const getFormApi = (brandID, formId) => {
  return sendGetRequest(`${API_LEADS.BASE_API_URL}${brandID}/forms/${formId}`)
}
export const getFormFieldsApi = (brandID, formId) => {
  return sendGetRequest(`${API_LEADS.BASE_API_URL}${brandID}/${API_LEADS.FORMS}/${formId}/fields`)
}
export const setFormFieldsApi = (brandID, formId, fields) => {
  return sendPutRequest(`${API_LEADS.BASE_API_URL}${brandID}/${API_LEADS.FORMS}/${formId}/fields`, fields)
}
export const getFormStaticsApi = (brandID, formId) => {
  return sendGetRequest(`${API_LEADS.BASE_API_URL}${brandID}/${API_LEADS.FORMS}/${formId}/statics`)
}
export const setFormStaticsApi = (brandID, formId, statics) => {
  return sendPutRequest(`${API_LEADS.BASE_API_URL}${brandID}/${API_LEADS.FORMS}/${formId}/statics`, statics)
}

export const getLeadApi = (brandID, formId, leadId) => {
  return sendGetRequest(`${API_LEADS.BASE_API_URL}${brandID}/forms/${formId}/leads/${leadId}`)
}

export const getFormSubmissionApi = (brandID, formId, leadId) => {
  return sendGetRequest(`${API_LEADS.BASE_API_URL}${brandID}/forms/${formId}/formSubmissions/${leadId}`)
}

export const getFormDefaultTranslations = brandID =>
  sendGetRequest(`${API_LEADS.BASE_API_URL}${brandID}/forms/defaultTranslations`)

export const getLastWidgetVersion = async () => {
  const versionJson = await sendGetRequest('https://lead-gen-widget.s3.eu-west-3.amazonaws.com/latest/version.json')
  return versionJson.version
}

export const putFormSettingsApi = (brandID, formId, settings) => {
  return sendPutRequest(`${API_LEADS.BASE_API_URL}${brandID}/forms/${formId}/settings`, settings)
}

export const putFormLocationSettingsApi = (brandID, formId, locationSettings) => {
  return sendPutRequest(`${API_LEADS.BASE_API_URL}${brandID}/forms/${formId}/locationSettings`, locationSettings)
}

export const putFormNotificationsApi = (brandID, formId, settings) => {
  return sendPutRequest(`${API_LEADS.BASE_API_URL}${brandID}/forms/${formId}/notifications`, settings)
}

export const putFormTranslationsApi = (brandID, formId, translations) => {
  return sendPutRequest(`${API_LEADS.BASE_API_URL}${brandID}/forms/${formId}/translations`, translations)
}

export const getConditionableFieldsApi = (brandID, locale, formId) => {
  return sendGetRequest(`${API_LEADS.BASE_API_URL}${brandID}/forms/${formId}/conditionableFields`, {
    headers: { locale: locale },
  })
}

export const getDynamicTranslationsApi = (formId, locale) => {
  return sendGetRequest(`${API_LEADS.BASE_API_URL}/public/forms/${formId}/dynamicTranslations`, {
    headers: { locale: locale },
  })
}

export const uploadFile = (clientId, file, formId, type) => {
  return sendPostRequest(`/gtr-api/clients/${clientId}/files?type=${type}`, file, {
    headers: { formId, 'Content-Type': 'multipart/form-data' },
  })
}

export const setPublicLeadToConverted = leadId => {
  return sendPutRequest(`/gtr-api/public/leads/${leadId}/convert`)
}

export const setPublicLeadToLost = leadId => {
  return sendPutRequest(`/gtr-api/public/leads/${leadId}/lose`)
}

export const useLeadToken = (leadToken, action = null) => {
  return sendPutRequest(
    `/gtr-api/leadTokens/${leadToken}/use`,
    {},
    action && {
      params: { action },
    }
  )
}

export const updateLeadToken = (leadToken, leadUpdated) => {
  return sendPutRequest(`/gtr-api/leadTokens/${leadToken}/update`, leadUpdated)
}

export const undoLeadTokenAction = link => {
  return sendPutRequest(`/gtr-api${link}`)
}

export const getAdministrators = (clientId, locationId) => {
  return sendGetRequest(`/gtr-api/clients/${clientId}/locations/${locationId}/administrators`)
}
