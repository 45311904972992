import Vue from 'vue'
import Vuei18n from 'vue-i18n'
import fr from './languages/fr.json'
import en from './languages/en.json'
import frPublic from './languages/public/en.json'
import enPublic from './languages/public/en.json'
import dePublic from './languages/public/de.json'
import itPublic from './languages/public/it.json'
import esPublic from './languages/public/es.json'
import noPublic from './languages/public/no.json'
import { deepMerge } from '@/utils/utilities.util'

Vue.use(Vuei18n)

const i18n = new Vuei18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    fr: deepMerge(fr, frPublic),
    en: deepMerge(en, enPublic),
    de: dePublic,
    it: itPublic,
    es: esPublic,
    no: noPublic,
  },
  silentTranslationWarn: process.env.NODE_ENV === 'production',
})

export default i18n
