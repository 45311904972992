import router from '@/router/router'
import { getAuthApi, postUpdatePasswordApi } from '@/services/auth.service'
import { getClientApi } from '@/services/client.service'
import frFR from '@kangc/v-md-editor/lib/lang/fr-FR'
import enUS from '@kangc/v-md-editor/lib/lang/en-US'

import i18n from '@/i18n/i18n'
import vuetify from '@/plugins/vuetify'
import markdown from '@/plugins/markdown'
import { getShortLocal } from '@/utils/utilities.util'

const SET_IS_LOGGED = 'SET_IS_LOGGED'
const SET_CURRENT_USER = 'SET_CURRENT_USER'
const SET_CURRENT_MODULE = 'SET_CURRENT_MODULE'
const SET_NAVIGATION = 'SET_NAVIGATION'
const SET_CURRENT_NAVIGATION = 'SET_CURRENT_NAVIGATION'
const SET_MODULES = 'SET_MODULES'
const SET_CLIENTS = 'SET_CLIENTS'
const SET_DRAWER = 'SET_DRAWER'
const SET_LOADING = 'SET_LOADING'
const SET_UPDATING = 'SET_UPDATING'
const SET_WORKING = 'SET_WORKING'
const SET_MENUING = 'SET_MENUING'
const SET_ALERT = 'SET_ALERT'
const RESET_STATE = 'RESET_STATE'
const SET_CURRENT_CLIENT = 'SET_CURRENT_CLIENT'

const getDefaultState = () => {
  return {
    drawer: null,
    loading: true,
    updating: true,
    working: false,
    menuing: false,
    alert: {
      visible: false,
      color: '',
      text: '',
    },
    isLogged: false,
    currentUser: null,
    currentModule: null,
    currentClient: null,
    navigation: [],
    currentNavigation: [],
    modules: [],
    brands: [],
  }
}

const state = getDefaultState()

const mutations = {
  [SET_IS_LOGGED]: (state, boolean) => {
    state.isLogged = boolean
  },
  [SET_CURRENT_USER]: (state, user) => {
    state.currentUser = user
    i18n.locale = getShortLocal(user.locale)
    vuetify.framework.lang.current = i18n.locale

    markdown.lang.use('en-EN', enUS)
    if (i18n.locale === 'fr') {
      markdown.lang.use('fr-FR', frFR)
    }
  },
  [SET_CURRENT_MODULE]: (state, module) => {
    state.currentModule = module
  },
  [SET_NAVIGATION]: (state, navigation) => {
    state.navigation = navigation
  },
  [SET_CURRENT_NAVIGATION]: (state, navigation) => {
    state.currentNavigation = navigation
  },
  [SET_MODULES]: (state, modules) => {
    state.modules = modules
  },
  [SET_CLIENTS]: (state, brands) => {
    state.brands = brands
  },
  [SET_CURRENT_CLIENT]: (state, client) => {
    state.currentClient = client
    localStorage.setItem('clientId', client.uuid)
  },
  [SET_LOADING]: (state, boolean) => {
    state.loading = boolean
  },
  [SET_DRAWER]: (state, boolean) => {
    state.drawer = boolean
  },
  [SET_UPDATING]: (state, boolean) => {
    state.updating = boolean
  },
  [SET_WORKING]: (state, boolean) => {
    state.working = boolean
  },
  [SET_MENUING]: (state, boolean) => {
    state.menuing = boolean
  },
  [SET_ALERT]: (state, payload) => {
    state.alert = {
      ...state.alert,
      ...payload,
    }
  },
  [RESET_STATE]: state => {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async userLogout({ dispatch }) {
    dispatch('resetState')
  },
  async userUpdatePassword(_, payload) {
    await postUpdatePasswordApi(payload)
  },
  async getAuthentication({ commit, dispatch, rootState }) {
    let { user, modules, clients, menus } = await getAuthApi()

    commit(SET_CURRENT_USER, user)
    commit(SET_CLIENTS, clients)
    commit(SET_NAVIGATION, menus)

    await Promise.all([dispatch('manageCurrentClient', clients), dispatch('manageModules', modules)])
    dispatch('updateCurrentNavigation', rootState.backoffice.currentModule.module)

    commit(SET_IS_LOGGED, true)
  },
  async getClient({ commit }, clientUuid) {
    const client = await getClientApi(clientUuid)
    commit(SET_CURRENT_CLIENT, client)
  },
  async manageCurrentClient({ dispatch }, clients) {
    const localStorageClientId = localStorage.getItem('clientId')
    const paramsClientId = router.currentRoute.params.clientId

    const currentClient = clients.find(client => client.uuid === (paramsClientId || localStorageClientId)) || clients[0]

    await dispatch('getClient', currentClient.uuid)
  },
  async manageModules({ commit }, modules) {
    const { params, query } = router.currentRoute
    const moduleName = params.module || query.module
    let currentModule = modules.find(module => module.module === moduleName) || modules.find(m => m.default)

    if (!currentModule) currentModule = modules[0]

    commit(SET_MODULES, modules)
    commit(SET_CURRENT_MODULE, currentModule)
  },
  updateCurrentNavigation({ commit, rootState }, currentModule) {
    const currentNavigation = rootState.backoffice.navigation[currentModule]
      .map(nav => ({ ...nav, items: nav.items.filter(item => item.available) }))
      .filter(nav => nav.items.length)

    commit(SET_CURRENT_NAVIGATION, currentNavigation)
  },
  setClientByUuid({ commit, state }, clientId) {
    const newClient = state.brands.find(brand => brand.uuid === clientId)
    commit(SET_CURRENT_CLIENT, newClient)
  },
  setDrawer({ commit }, bool) {
    commit(SET_DRAWER, bool)
  },
  setLoading({ commit }, bool) {
    commit(SET_LOADING, bool)
  },
  setUpdating({ commit }, bool) {
    commit(SET_UPDATING, bool)
  },
  setWorking({ commit }, bool) {
    commit(SET_WORKING, bool)
  },
  setMenuing({ commit }, bool) {
    commit(SET_MENUING, bool)
  },
  setAlert({ commit }, payload) {
    commit(SET_ALERT, {
      visible: true,
      ...payload,
    })
  },
  resetAlert({ commit }) {
    commit(SET_ALERT, {
      visible: false,
    })
  },
  resetState({ commit }) {
    commit(RESET_STATE)
  },
}

const getters = {
  firstParentMenu: state => state.currentNavigation[0],
  firstChildMenu: state => state.currentNavigation[0].items[0],
  currentBrandName: state => {
    const brand = state.brands.find(brand => brand.uuid === state.currentClient.uuid)
    if (brand) {
      return brand.name
    }
    return ''
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
